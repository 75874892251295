<template>
  <Toast />

  <div class="card">
    <DataTable
      ref="dt"
      :value="records"
      dataKey="id"
      :paginator="true"
      :rows="10"
      class="dt"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5, 10, 25]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
      v-model:filters="filters"
      :globalFilterFields="['title','subtitle','keywords','author']"
      stateStorage="session" stateKey="piekir"
    >
      <template #header>
        <div class="table-header pop20">
          <div class="p-d-flex p-jc-between">
            <div>Manage piekirpedia records</div>
            <div>
            </div>
            <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                        </span>
          </div>
        </div>
      </template>

      <Column
        field="id"
        header="Id"
        :sortable="true"
        headerStyle="width: 5%"
      ></Column>
      <Column header="Image">
        <template #body="slotProps">
          <img
            :src="
              `${$config.images.server}/images/${slotProps.data.media}?width=80&height=80&fit=contain&background=ffffff`
            "
            :alt="slotProps.data.title"
            class="small_record_image"
            v-if="slotProps.data.media"
          />
          <img
            :src="`${$config.images.server}/images/noimage.jpg?width=80&height=80&fit=contain&background=ffffff`"
            class="record_image"
            v-else
          />
        </template>
      </Column>
      <Column field="title" header="Title" :sortable="true"></Column>
      <Column field="subtitle" header="Subtitle" :sortable="true"></Column>
      <Column field="keywords" header="Keywords" :sortable="true"></Column>
      <Column field="author" header="Author" :sortable="true"></Column>
      <Column field="updatedAt" header="Modified" :sortable="true"></Column>
      <Column>
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editrecord(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
  </div>

  <Dialog
    v-model:visible="recordDialog"
    :style="{ width: '450px' }"
    header="Piekipedia record details"
    :maximizable="true"
    :modal="true"
    class="p-fluid"
  >
    <p class="center-image">
      <img
        ref="currentImage"
        :src="
          `${$config.images.server}/images/${record.media}?width=150&height=150&fit=contain&background=ffffff`
        "
        :alt="record.title"
        class="big_record_image"
        v-if="record.media"
      />
      <img :src="`${$config.images.server}/images/noimage.jpg?width=150&height=150&fit=contain&background=ffffff`" class="record_image" v-else />
    </p>
    <div class="upload">
    </div>
    <div class="p-field">
      <label for="title">Title</label>
      <InputText
        id="title"
        v-model.trim="record.title"
        required="true"
        autofocus
        :class="{ 'p-invalid': submitted && !record.title }"
      />
      <small class="p-error" v-if="submitted && !record.title"
        >Title is required.</small
      >
    </div>
    <div class="p-field">
      <label for="subtitle">Subtitle</label>
      <InputText id="subtitle" v-model.trim="record.subtitle" required="true"         :class="{ 'p-invalid': submitted && !record.subtitle }"
      />
      <small class="p-error" v-if="submitted && !record.subtitle"
        >Subtitle is required.</small>
    </div>
    <div class="p-field">
      <label for="keywords">Keywords (separate keywords with ;)</label>
      <InputText id="keywords" v-model.trim="record.keywords" required="true"         :class="{ 'p-invalid': submitted && !record.keywords }"
      />
      <small class="p-error" v-if="submitted && !record.keywords"
        >Keywords are required.</small>
    </div>
    <div class="p-field">
      <label for="shortdescription">Shortdescription</label>
      <InputText id="shortdescription" v-model.trim="record.shortdescription" required="true"         :class="{ 'p-invalid': submitted && !record.shortdescription }"
      />
      <small class="p-error" v-if="submitted && !record.shortdescription"
        >Short description is required.</small>
    </div>
    <div class="p-field">
      <label for="description">Description</label>
      <QuillEditor ref="editor" id="editor" theme="snow" toolbar="full"  :modules="modules" contentType="html" v-model:content="record.description" />
    </div>
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="hideDialog"
      />
    </template>
  </Dialog>
</template>

<script>
import recordService from "../../services/record_service"
// import config from "../../../config"

// import Dropdown from "primevue/dropdown"

import { QuillEditor } from '@vueup/vue-quill'
import BlotFormatter from 'quill-blot-formatter'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import {FilterMatchMode} from 'primevue/api';

export default {
  components: {
//    Dropdown,
    QuillEditor
  },
  setup: () => {
    const modules = {
      name: 'blotFormatter',  
      module: BlotFormatter, 
      options: {/* options */}
    }
    return { modules }
  },
  data() {
    return {
      records: null,
      recordDialog: false,
      deleterecordDialog: false,
      record: {},
      submitted: false,
      isnew: false,
      files: [],
      displayUpload: false,
      displayChoose: false,
      tjalierecords: [{ name: "None", id: 0 }],
      filters: null
    }
  },
  recordservice: null,
  created() {
    this.recordservice = new recordService()
    this.filters = {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}}
  },
  mounted() {
    this.recordservice.getPiekirpedia().then((data) => {
      this.records = data
      data.forEach((item) => {
        this.tjalierecords.push({
          name: item.title + " (" + item.subtitle + ")",
          id: item.id,
        })
      })
    })
  },
  methods: {
    openUpload() {
      this.displayUpload = true
    },
    openNew() {
      this.record = {}
      this.submitted = false
      this.recordDialog = true
      this.isnew = true
    },
    hideDialog() {
      this.recordDialog = false
      this.submitted = false
    },
    saverecord(close) {
      this.submitted = true
      //defaults for piekir
      this.record.recordtype = 2
      this.record.recordcategory = 0
      this.record.connectedId = 0
      this.record.linkedId = 0
      this.record.author = JSON.parse(localStorage.getItem("user")).username
      this.record.relatedmedia = "0"

      if (this.record.title.trim()) {
        if (this.record.id) {
          this.recordservice
            .update(this.record)
            .then(() => {
              this.recordservice
                .getPiekirpedia()
                .then((data) => (this.records = data))
              this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: "record Updated",
                life: 3000,
              })
            })
            .catch((err) => {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err,
                life: 3000,
              })
            })
        } else {
          this.recordservice
            .add(this.record)
            .then(() => {
              this.recordservice
                .getPiekirpedia()
                .then((data) => {
                  this.records = data
                  this.record = this.records[data.length-1]})
              this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: "record Created",
                life: 3000,
              })
            })
            .catch((err) => {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err,
                life: 3000,
              })
            })
        }
        if (close) {
          this.recordDialog = false
          this.record = {}
        }
      }
    },
    editrecord(record) {
      this.isnew = false
      this.record = { ...record }
      //this.$refs.editor.setHTML(this.record.description)
      this.recordDialog = true
    },
    confirmDeleterecord(record) {
      this.record = record
      this.deleterecordDialog = true
    },
    deleterecord() {
      this.recordservice
        .delete(this.record.id)
        .then(() => {
          this.recordservice.getPiekirpedia().then((data) => (this.records = data))
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "record deleted",
            life: 3000,
          })
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err,
            life: 3000,
          })
        })
      this.deleterecordDialog = false
      this.record = {}
    },
    onUpload(event) {
      this.record.media = event.files[0].name
      this.displayUpload = false
    },
    prepareUpload(event) {
      let user = JSON.parse(localStorage.getItem("user"))
      //event.xhr.open('POST', 'http://localhost:4000/records/upload', true);
      //event.xhr.withCredentials = true;
      event.xhr.setRequestHeader("Authorization", "Bearer " + user.token)
    },
  },
}
</script>

<style scoped>
/* .small_record_image {
    width: 100px;
}
.big_record_image {
    width: 300px;
}
 */
.basemap {
  width: 100%;
  height: 100%;
}
.center-image {
  text-align: center;
}
</style>
